var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[(_vm.isNotHidden)?_c('li',{class:[
      "flex px-4 py-2 items-center select-none",
      {
        'cursor-pointer': _vm.isParent && !_vm.disabled,
      } ],attrs:{"data-testid":("treelist__item-" + (_vm.data.name)),"tabindex":_vm.isParent ? 0 : null},on:{"click":_vm.onClickItem,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onClickItem($event)}}},[_c('div',{staticClass:"pr-4 w-8"},[(_vm.isParent)?_c('ExpandIcon',{class:[
          'transform duration-200',
          {
            'transform rotate-180 duration-200': !_vm.isOpen,
            'opacity-0': !_vm.isParent,
          } ]}):_vm._e()],1),_c('CheckBox',{attrs:{"data-testid":("treelist__checkbox-" + (_vm.data.name)),"disabled":_vm.disabled,"isChecked":_vm.data.isChecked},on:{"click":_vm.onClickCheckbox}}),_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(_vm.data.name))])],1):_vm._e(),(_vm.isOpen)?_c('div',{staticClass:"pl-9"},_vm._l((_vm.data.children),function(item,index){return _c('TreeList',{key:index,attrs:{"data":item,"meta":_vm.meta,"disabled":item.isDisabled()}})}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }