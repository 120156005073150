



















import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import CheckCircleIcon from '@/app/ui/assets/icon_check_circle.vue'

@Component({
  components: {
    Modal,
    Button,
    CheckCircleIcon,
  },
})
export default class ModalSuccess extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: 'Title' }) private title!: string
  @Prop({ default: 'Description' }) private description!: string

  private hasSlot(slotName: string) {
    return !!this.$slots[slotName] || !!this.$scopedSlots[slotName]
  }
}
